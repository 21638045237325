.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #FB0605;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  /* color: rgb(228, 93, 93); */
}
.App-header  > .logo,.App-header  > .balance {
  margin: auto 5px;
  color: white;
  display: flex;
  align-items: center;
  font-size: 18px;
}

.App-header  > div {
  margin: auto;
}

.logo img {
  width: 50px;
}

.App-link {
  color: #61dafb;
}
.ngc-title {
  color : white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* done animation */

.success-animation { margin:150px auto;}
.checkmark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #4bb71b;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #4bb71b;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    position:relative;
    top: 5px;
    right: 5px;
   margin: 0 auto;
}
.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #4bb71b;
    fill: #fff;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
 
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #4bb71b;
    }
}
.Toastify__toast-theme--dark {
  min-height: unset !important;
  border-radius: 5px  !important;
}
.custom-toast-container {
  max-width: unset;
  width: auto !important;
  justify-content: center;
  display: flex;
  top: 50vh !important;
}

.Toastify__close-button {
  display: none;
}

.Toastify__toast-icon {
  display: none !important;
}

.Toastify__toast-body {
  padding: 0px !important; /* Adjust padding as needed */
}

.Toastify__toast--zoomIn {
  animation: zoomIn 0.1s ease !important; /* Adjust duration as needed */
}

/* Keyframes for zooming animation */
@keyframes zoomIn {
  from {
    transform: scale(0); /* Start from no scale */
    opacity: 0; /* Start from transparent */
  }
  to {
    transform: scale(1); /* End at full scale */
    opacity: 1; /* End at fully visible */
  }
}

/* no record style */

.empty-state {
  /* width: 750px;
  margin: 40px auto;
  background: #ffffff;
  box-shadow: 1px 2px 10px #e1e3ec;
  border-radius: 4px; */
  /* margin: 40px auto; */
  height: 78vh;
}

.empty-state__content {
  padding: 48px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.empty-state__icon {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  border-radius: 200px;
  justify-content: center;
  background-color: #f7fafc;
  box-shadow: 0px 2px 1px #e1e3ec;
}

.empty-state__icon img {
  width: 170px;
}

.empty-state__message {
  color: #38a169;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 0.85rem;
}

.empty-state__help {
  color: #a2a5b9;
  font-size: 0.875rem;
}

.credit {
  color: #A2A5B9;
  font-size: .75rem;
  text-align: center;
}

.credit a {
  color: #444;
}

.section-headline {
  padding: 10px 0;
  position: relative
}

.section-headline h3 {
  font-size: 28px;
  line-height: 38px;
  color: #f0ed3cf7
}


.result-container {
  background-color: #fc0904
}

.result-table {
  background-color: #fff
}

.rounded-sc {
  /* padding: 0px 3rem; */
  text-align: center
}

.number-sc {
  color: #1711dc;
  font-family: Arial Black,sans-serif;
  font-size: 2.5vh;
  font-weight: 900
}

.number-sc.round-spinning .digit {
  border: 2px solid red;
  border-radius: 50%;
  display: inline-block;
  width: 3vw;
  height: 3vw;
  text-align: center;
  line-height: 3vw;
  margin-right: -1vw
}

.post-code-sc {
  color: red;
  font-family: Moul,sans-serif;
  font-size: 2.5vh;
}

.t-head {
  background-color: #fc0904;
  color: #fff
}

.t-head a {
  color: #fff
}
